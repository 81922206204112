<template>
  <div>
    <div>测试页面</div>
    <div v-for=" item in msgList"> {{ item }}</div>
    <el-input v-model="inputData"></el-input>
    <el-button @click="send" >发送</el-button>
  </div>

</template>
<script>
import flvCom from '@/components/flvCom.vue'
import {mapGetters} from "vuex";

export default {
  name: 'test',
  components: { flvCom },
  data() {
    return {
      inputData: '332',
      msgList: [],
    }
  },
  computed: {
    ...mapGetters(["socketMsgReceive"]),
  },
  mounted() {
    this.init()
  },
  filters: {
  },
  methods: {
    init() {
    },
    send() {
      this.$store.dispatch("SEND_MSG", "100001" + JSON.stringify(this.inputData))
    }
  },
  watch: {
    socketMsgReceive(newVal, oldVal) {
      //可以对数据执行相应的操作
      console.log(newVal,oldVal)
      this.msgList.push(newVal)
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
